
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { IUserLevel } from '@/core/data/userLevel';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'user-level-listing',
	components: {
		Datatable
	},
	setup() {
		const loading = ref(true);
		const store = useStore();
		const checkedUsers = ref([]);
		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'label',
				sortable: true
			},
			// {
			// 	name: 'Id',
			// 	key: 'id',
			// 	sortable: true
			// },
			{
				name: 'Level',
				key: 'level',
				sortable: true
			},
			// {
			// 	name: 'Customer Id',
			// 	key: 'customerId',
			// 	sortable: true
			// },
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		const tableData = ref<Array<IUserLevel>>([]);
		const initUser = ref<Array<IUserLevel>>([]);

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const getUserLevelList = () => {
			ApiService.get(`UserLevel`)
				.then(({ data }) => {
					tableData.value.splice(0, tableData.value.length, ...data);
					initUser.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					loading.value = false;
					tableData.value.splice(0, tableData.value.length);
					initUser.value.splice(0, tableData.value.length);
					console.log('response', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('User Level', []);
			getUserLevelList();
		});

		const deleteFewUsers = () => {
			checkedUsers.value.forEach(item => {
				deleteData(item);
			});
			checkedUsers.value.length = 0;
		};

		const deleteUserLevel = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const deleteData = id => {
			ApiService.delete(`UserLevel/${id}`)
				.then(({ data }) => {
					console.log('data', data);
					getUserLevelList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const search = ref<string>('');
		const searchItems = () => {
			tableData.value.splice(0, tableData.value.length, ...initUser.value);
			if (search.value !== '') {
				let results: Array<IUserLevel> = [];
				for (let j = 0; j < tableData.value.length; j++) {
					if (searchingFunc(tableData.value[j], search.value)) {
						results.push(tableData.value[j]);
					}
				}
				tableData.value.splice(0, tableData.value.length, ...results);
			}
		};

		const searchingFunc = (obj, value): boolean => {
			for (let key in obj) {
				if (
					!Number.isInteger(obj[key]) &&
					!(typeof obj[key] === 'object') &&
					!(typeof obj[key] === 'boolean')
				) {
					if (obj[key].indexOf(value) != -1) {
						return true;
					}
				}
			}
			return false;
		};

		return {
			search,
			loading,
			tableData,
			tableHeader,
			searchItems,
			checkedUsers,
			deleteFewUsers,
			deleteUserLevel,
			getUserLevelList,
			selectedCustomer
		};
	}
});
